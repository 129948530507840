import { createSelector } from '@ngxs/store';
import { denormalize } from 'normalizr';
import { produce } from 'immer';
import _ from 'lodash';

import {
  Diner,
  MealPlanType,
  MenuItemType,
  NormalizedEntities,
  Seat,
  Table,
  Ticket,
  TicketItemStatus,
  TicketStatus,
  dinersSchema,
  seatsSchema,
  tableSchema,
  ticketsSchema
} from 'src/app/models';
import { TICKETS_STATE_TOKEN } from './tickets/tickets.state';
import { TicketHelper } from 'src/app/helpers/ticket.helper';
import { TableHelper } from 'src/app/helpers';
import { DINERS_STATE_TOKEN } from 'src/app/store/diners/diners.state';
import { SeatsStateSelectors } from 'src/app/store/seats/seats.state.selectors';
import { TicketItemsStateSelectors } from 'src/app/store/ticket-items/ticket-items-state.selectors';
import { TABLES_STATE_TOKEN } from 'src/app/store/tables/tables.state.model';
import { TicketsStateSelectors } from 'src/app/store/tickets/tickets.selectors';
import { MenuSelectors } from 'src/app/store/menu/menu.selector';
import { TRANSACTIONS_STATE_TOKEN } from 'src/app/store/transactions/transactions.state';
import { LocationSelectors } from 'src/app/store/location/location.selectors';
import { DinersSelectors } from 'src/app/store/diners/diners.selectors';

export interface MealPlanTicketInfo {
  mealplan_tender_type: MealPlanType;
  meal_plan_id: number;
}

export class UtilsSelectors {
  static selectedTicket = createSelector(
    [
      TICKETS_STATE_TOKEN,
      TRANSACTIONS_STATE_TOKEN,
      DinersSelectors.dinersById,
      TicketItemsStateSelectors.ticketItemsById,
      TicketItemsStateSelectors.getWaitingTicketItem
    ],
    (
      ticketsState,
      transactionState,
      normalizedDiners,
      normalizedTicketItems,
      waitingTicketItem
    ) => {
      try {
        const selectedTicketId = ticketsState.selectedTicket.data;
        const normalizedTransactions = transactionState.transactions.byId;
        if (selectedTicketId) {
          const ticketsById = ticketsState.tickets.byId;
          const entities = {
            tickets: ticketsById,
            ticket_items: normalizedTicketItems,
            transactions: normalizedTransactions
          };

          const denormalizeSelectedTicket: Ticket = denormalize(
            selectedTicketId,
            ticketsSchema,
            entities
          );

          if (denormalizeSelectedTicket) {
            if (denormalizeSelectedTicket.diner_id) {
              denormalizeSelectedTicket.diner = denormalize(
                denormalizeSelectedTicket.diner_id,
                dinersSchema,
                {
                  diners: normalizedDiners
                }
              ) as Diner;
            }
            denormalizeSelectedTicket.ticket_items = _.chain(
              denormalizeSelectedTicket.ticket_items
            )
              .filter((i) => !i.parent_uuid)
              .sortBy('created_at')
              .value();
            if (waitingTicketItem && waitingTicketItem.length > 0) {
              // applied waiting item for normal items
              denormalizeSelectedTicket.ticket_items =
                denormalizeSelectedTicket.ticket_items.concat(
                  (waitingTicketItem as any[]).filter((x) => !x.parent_uuid)
                );
              (waitingTicketItem as any[])
                .filter((x) => x.parent_uuid)
                .forEach((x) => {
                  const parent = denormalizeSelectedTicket.ticket_items.find(
                    (i) => i.device_ticket_item_uuid === x.parent_uuid
                  );
                  if (parent) {
                    parent.ticket_items = parent.ticket_items || [];
                    parent.ticket_items.push(x);
                  }
                });
            }
            denormalizeSelectedTicket.local_attributes = {
              isItemsFired:
                denormalizeSelectedTicket.ticket_items &&
                denormalizeSelectedTicket.ticket_items.every(
                  (x) => x?.status === TicketItemStatus.FIRED
                )
            };
            denormalizeSelectedTicket.formatted_takeout_date_time =
              TicketHelper.calculateFormattedTakeoutDateTime(
                denormalizeSelectedTicket
              );

            return denormalizeSelectedTicket;
          }
        }
      } catch (e) {
        console.error('Error in UtilsSelectors.selectedTicket', e);
      }
    }
  );

  static canDiscount = createSelector(
    [
      TICKETS_STATE_TOKEN,
      TicketItemsStateSelectors.getSelectedTicketItem,
      TicketItemsStateSelectors.ticketItemsById,
      MenuSelectors.getMenuItems
    ],
    (ticketsState, selectedTicketItem, ticketItems, menuItems) => {
      const selectedTicketId = ticketsState.selectedTicket.data;
      const ticketsById = ticketsState.tickets.byId;
      const selectedTicket =
        !ticketsById || !selectedTicketId
          ? null
          : ticketsById[selectedTicketId];
      const isTicketPaid = [
        TicketStatus.PARTIAL_PAID,
        TicketStatus.PAID
      ].includes(selectedTicket.status);
      const isTicketItemVoided =
        selectedTicketItem?.status === TicketItemStatus.VOIDED;
      const hasAppliedPointsPlan =
        selectedTicket.meal_plan?.tender_type === MealPlanType.POINTS;

      let canBeDiscounted = true;

      // if ticket is paid or ticket item is voided, it can't be discounted
      if (isTicketPaid || isTicketItemVoided) {
        canBeDiscounted = false;
      } else if (selectedTicketItem) {
        // if ticket item is selected
        canBeDiscounted =
          menuItems.find((mi) => {
            if (mi.type === MenuItemType.Food) {
              return mi.food_id === selectedTicketItem.food_id;
            }
            return mi.id === selectedTicketItem.pos_product_id;
          })?.can_be_discounted &&
          (!hasAppliedPointsPlan || !selectedTicketItem.mealplan_valid_item); // if ticket item has applied points plan, it can't be discounted
      }

      return canBeDiscounted;
    }
  );

  static getSelectedTable = createSelector(
    [
      TABLES_STATE_TOKEN,
      SeatsStateSelectors.seatsById,
      TICKETS_STATE_TOKEN,
      TicketItemsStateSelectors.getTicketItems,
      DINERS_STATE_TOKEN,
      TRANSACTIONS_STATE_TOKEN
    ],
    (
      tableState,
      seats,
      ticketsState,
      ticketItems,
      dinersState,
      transactionState
    ) => {
      const tables = tableState.tables;
      const selectedTableId = tableState.selectedTable.data;
      const tickets = ticketsState.tickets;
      const transactionsById = transactionState.transactions.byId;

      if (selectedTableId == null) {
        return null;
      }

      const entities: NormalizedEntities = {
        tables: tables.byId,
        seats,
        tickets: tickets.byId,
        ticket_items: ticketItems.byId,
        diners: dinersState.diners.byId,
        transactions: transactionsById
      };

      const denormalizedTable: Table = denormalize(
        selectedTableId,
        tableSchema,
        entities
      );

      if (denormalizedTable && denormalizedTable.seats) {
        denormalizedTable.seats = _.sortBy(denormalizedTable.seats, 'number');
        denormalizedTable.seats.forEach((s) => {
          if (s && s.ticket && s.ticket.ticket_items) {
            // This filter was not here before but had to add it to prevent app from displaying modifiers as regular items
            // Please investigate root cause for this happening
            // DEF-3210 - When cancelling any item from ticket screen , at checkout modifiers are getting duplicated.
            s.ticket.ticket_items = s.ticket.ticket_items.filter(
              (ti) => !ti.parent_uuid
            );
            s.ticket.ticket_items = _.sortBy(
              s.ticket.ticket_items,
              'created_at'
            );
          }
        });
      }

      return denormalizedTable;
    }
  );

  static getSelectedCheckoutTable = createSelector(
    [UtilsSelectors.getSelectedTable],
    (table) => {
      const checkoutTable = _.cloneDeep(table);

      produce(checkoutTable, (draft: Table) => {
        draft.seats
          .filter((s) => !!s.ticket?.ticket_items?.length)
          .forEach((s) =>
            _.remove(s.ticket.ticket_items, (ti) => !ti.parent_uuid)
          );
      });
      return checkoutTable;
    }
  );

  static getSeatsCourseStatuses = createSelector(
    [
      UtilsSelectors.getSelectedTable,
      LocationSelectors.getIsCoursingEnabled,
      TicketsStateSelectors.getTicketBySeatId,
      TicketItemsStateSelectors.getTicketItemByUuid
    ],
    (
      selectedTable,
      isCoursingEnabled,
      getTicketBySeatId,
      getTicketItemByUuid
    ) => {
      if (selectedTable?.seats?.length) {
        return TableHelper.seatsStatues(
          selectedTable.seats,
          isCoursingEnabled,
          getTicketBySeatId,
          getTicketItemByUuid
        );
      }
      return [];
    }
  );

  static getSelectedSeat = createSelector(
    [
      TICKETS_STATE_TOKEN,
      SeatsStateSelectors.getSelectedSeatId,
      SeatsStateSelectors.seatsById
    ],
    (ticketsState, selectedSeat, seats) => {
      if (!selectedSeat) {
        return null;
      }
      const entities = {
        seats,
        tickets: ticketsState.tickets.byId
      };

      return denormalize(selectedSeat, seatsSchema, entities) as Seat;
    }
  );
}
