import { createPropertySelectors, createSelector } from '@ngxs/store';

import { UtilsSelectors } from 'src/app/store/utils.selectors';
import { SelfServiceState, SelfServiceStateModel } from './self-service.state';
import {
  MealPlanType,
  SelfServiceTicketItem,
  Ticket,
  TicketItem,
  TicketItemStatus
} from 'src/app/models';
import _ from 'lodash';

export class SelfServiceSelectors {
  static slices =
    createPropertySelectors<SelfServiceStateModel>(SelfServiceState);

  static isSskMenuLoaded = createSelector(
    [SelfServiceSelectors.slices.isMenuLoaded],
    (isMenuLoaded) => isMenuLoaded
  );

  static isMacroGridLoaded = createSelector(
    [SelfServiceSelectors.slices.isMacroGridLoaded],
    (isMacroGridLoaded) => isMacroGridLoaded
  );

  static isMenuLoaded = createSelector(
    [
      SelfServiceSelectors.isSskMenuLoaded,
      SelfServiceSelectors.isMacroGridLoaded
    ],
    (isMenuLoaded, isMacroGridLoaded) => isMenuLoaded && isMacroGridLoaded
  );

  static isSetupComplete = createSelector(
    [SelfServiceSelectors.slices.isSetupComplete],
    (isSetupComplete) => isSetupComplete
  );

  static focusedCategory = createSelector(
    [SelfServiceSelectors.slices.focusedCategory],
    (focusedCategory) => focusedCategory
  );

  static focusedMenuItem = createSelector(
    [SelfServiceSelectors.slices.focusedMenuItem],
    (focusedMenuItem) => focusedMenuItem
  );

  static getSelectedTicket = createSelector(
    [UtilsSelectors.selectedTicket],
    (prevTicket) => SelfServiceSelectors.getSskTicket(prevTicket)
  );

  private static convertTicketItemToKioskItem(
    ticket: Ticket,
    ticketItem: TicketItem
  ): SelfServiceTicketItem {
    let childItems = [];

    if (ticketItem.ticket_items?.length) {
      childItems = ticketItem.ticket_items.map((ti) =>
        this.convertTicketItemToKioskItem(ticket, ti)
      );
    }

    return {
      name: ticketItem.name,
      type: ticketItem.type,
      food_id: ticketItem.food_id,
      plate_id: ticketItem.plate_id,
      pos_product_id: ticketItem.pos_product_id,
      price: +ticketItem.pre_tax_subtotal || 0,
      items: [ticketItem],
      childItems,
      total: 1,
      meal_plan_calculation_note: ticketItem.meal_plan_calculation_note,
      mealplan_discount_applied: +ticketItem.mealplan_discount_applied,
      mealPlanValidItem: !!ticketItem.mealplan_valid_item,
      device_ticket_item_uuid: ticketItem.device_ticket_item_uuid,
      isCovered:
        !!ticket.mealplan_tender_type &&
        ticket.mealplan_tender_type !== MealPlanType.DECLINING_BALANCE,
      isCoveredByDeclining:
        (!!ticket.mealplan_tender_type &&
          ticket.mealplan_tender_type === MealPlanType.DECLINING_BALANCE &&
          +ticketItem.pre_tax_subtotal === 0) ||
        ticketItem.local_attributes?.removed
    };
  }

  // move from self-service.selector.ts
  private static getSskTicket(prevTicket: Ticket): Ticket {
    const ticket: Ticket = _.cloneDeep(prevTicket);
    const ticket_items = ticket.ticket_items.filter(
      (ti) => ti.status !== TicketItemStatus.VOIDED
    );

    const hasChildItems = ticket.ticket_items.filter(
      (x) => x.ticket_items?.length
    );
    let groupedTicketItems: SelfServiceTicketItem[] = [];

    if (hasChildItems?.length) {
      groupedTicketItems = hasChildItems.map((item) =>
        this.convertTicketItemToKioskItem(ticket, item)
      );
    }

    // normal ticket items mean don't have parent uuid and don't have child items.
    const normalTicketItems = ticket_items.filter(
      (x) => !x.ticket_items?.length
    );
    const kioskChildNormalItem = Object.entries(
      _.groupBy(
        normalTicketItems,
        (item) =>
          item.name + (item.food_id || item.pos_product_id || item.plate_id)
      )
    ).map(([, value]) => {
      const firstItem = value[0];
      const basePrice = _.sum(value.map((i) => +i.base_price || 0));
      const discountAmount = _.sum(value.map((i) => +i.discount_amount || 0));

      const item: SelfServiceTicketItem = {
        name: firstItem.name,
        type: firstItem.type,
        pos_product_id: firstItem.pos_product_id,
        plate_id: firstItem.plate_id,
        food_id: firstItem.food_id,
        basePrice,
        discountAmount,
        price: _.sum(value.map((i) => +i.pre_tax_subtotal || 0)),
        items: value,
        total: value.length,
        meal_plan_calculation_note: firstItem.meal_plan_calculation_note,
        mealplan_discount_applied: _.sum(
          value.map((i) => +i.mealplan_discount_applied)
        ),
        mealPlanValidItem: !!value[0].mealplan_valid_item,
        device_ticket_item_uuid: _.last(value).device_ticket_item_uuid,
        isCovered:
          !!ticket.mealplan_tender_type &&
          ticket.mealplan_tender_type !== MealPlanType.DECLINING_BALANCE,
        isCoveredByDeclining:
          (!!ticket.mealplan_tender_type &&
            ticket.mealplan_tender_type === MealPlanType.DECLINING_BALANCE &&
            +firstItem.pre_tax_subtotal === 0) ||
          firstItem.local_attributes?.removed,
        createdAt: firstItem.created_at
      };
      return item;
    });

    ticket.local_attributes.ticketItems = [
      ...groupedTicketItems,
      ...kioskChildNormalItem
    ];

    if (!ticket.mealplan_balance_used && ticket.meal_plan) {
      ticket.mealplan_balance_used = _.toNumber(ticket.meal_plan.balance_used);
    }
    if (ticket.transactions) {
      ticket.transactions = ticket.transactions.filter((t) => t);
    }

    return ticket;
  }
}
