import { Observable, lastValueFrom, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import _ from 'lodash';

import {
  CloseTicketWsEvent,
  CreateTicketWsEvent,
  TicketClosedWsEvent,
  TicketLockedChangedFromWS
} from 'src/app/store/tickets/tickets.action';
import {
  CreateTableReservationSuccess,
  DeleteTableReservationSuccess,
  EditTableReservationSuccess
} from 'src/app/store/tables/reservations/table-reservation.action';
import {
  DeleteQuantityRestriction,
  UpdateQuantityRestriction
} from '../store/menu/menu.action';
import {
  FireCourseNotificationEvent,
  FireCourseSeatNotificationEvent,
  FireTableNotificationEvent,
  FireTicketItemNotificationEvent,
  FireTicketNotificationEvent
} from 'src/app/store/websocket.actions';
import {
  FloorplanOrderUpdatedWS,
  GetTables,
  PendingSeatAssignmentSeatedWsEvent,
  ReservationCreatedWsEvent,
  TableStatusChangedWs,
  TicketCreatedWsEvent,
  UpdateDataAfterAssignment
} from 'src/app/store/tables/tables.action';
import {
  LocationType,
  TakeoutOrderStatus,
  TicketStatus,
  WSMessage,
  WebsocketEvent
} from 'src/app/models';
import {
  PendingAssignmentDeletedWsEvent,
  PendingSeatCreatedWsEvent
} from 'src/app/store/host-mode/host-mode.action';
import {
  UpdateTakeoutDeliveryLocked,
  UpdateTakeoutDeliveryStatus
} from 'src/app/store/takeout-delivery/takeout-delivery.action';
import { CommonConstant } from 'src/app/constants';
import { UpdateLocalSeat } from 'src/app/store/seats/seats.action';
import { LogRocketProvider, WebSocketProvider } from '.';
import { TABLES_STATE_TOKEN } from 'src/app/store/tables/tables.state.model';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';

const TAKEOUT_DELIVERY_LISTING_URL = '/takeout-delivery';

@Injectable({
  providedIn: 'root'
})
export class PosProvider {
  private posChannelSubscription: Observable<any>;

  constructor(
    private readonly store: Store,
    private readonly webSocketProvider: WebSocketProvider,
    private readonly lgProvider: LogRocketProvider,
    private readonly router: Router
  ) {}

  initPosChannelSubscription(deviceId: number, locationId: number) {
    if (this.posChannelSubscription) {
      return;
    }

    this.posChannelSubscription = this.subscribePosChannelForReport(locationId);
    this.getDataFromPosChannel(deviceId);
  }

  resetPosChannelSubscription() {
    this.posChannelSubscription = null;
    this.webSocketProvider.unsubscribe(
      CommonConstant.WEBSOCKET_CHANNELS.POS_CHANNEL
    );
  }

  private subscribePosChannelForReport(locationId: number) {
    return this.webSocketProvider.subscribeTo(
      CommonConstant.WEBSOCKET_CHANNELS.POS_CHANNEL,
      { pos_location_id: locationId }
    );
  }

  private getDataFromPosChannel(deviceId: number) {
    if (!this.posChannelSubscription) {
      return;
    }

    return lastValueFrom(
      this.posChannelSubscription.pipe(
        map((response) => this.getWsMessageActions(response, deviceId))
      )
    );
  }

  private async getWsMessageActions(message: WSMessage, deviceId: number) {
    const selectedTableId =
        this.store.selectSnapshot(TABLES_STATE_TOKEN).selectedTable.data,
      url = this.router.url,
      isAtTakeoutDeliveryListingPage = url === TAKEOUT_DELIVERY_LISTING_URL;

    // all location types just receive the  WebsocketEvent.QUANTITY_RESTRICTION_CHANGED & WebsocketEvent.QUANTITY_RESTRICTION_DELETED
    this.handleAllLocationEvents(message);

    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;

    // takeout order status change & ticket lock changed are using for takeout delivery (it means Quick Service & Dining Room)
    // but ticket locked is also required for dining room
    // WebsocketEvent.TAKEOUT_ORDER_STATUS_CHANGE, WebsocketEvent.TICKET_LOCK_CHANGED
    switch (locationType) {
      case LocationType.QuickService:
        if (isAtTakeoutDeliveryListingPage) {
          this.handleTakeOutOrderStatusChangeEvent(message);
          this.handleTicketLockChangeEvent(
            message,
            deviceId,
            selectedTableId,
            isAtTakeoutDeliveryListingPage
          );
        }

        break;
      case LocationType.DiningRoom:
        if (isAtTakeoutDeliveryListingPage) {
          this.handleTakeOutOrderStatusChangeEvent(message);
        }
        this.handleTicketLockChangeEvent(
          message,
          deviceId,
          selectedTableId,
          isAtTakeoutDeliveryListingPage
        );
        this.handleDiningRoomEvents(message, deviceId, selectedTableId);
        break;
    }
  }

  private handleAllLocationEvents(message: WSMessage) {
    if (
      [
        WebsocketEvent.QUANTITY_RESTRICTION_CHANGED,
        WebsocketEvent.QUANTITY_RESTRICTION_DELETED
      ].includes(message.event)
    ) {
      this.lgProvider.log('WS Quantity Restriction Event', message);
      switch (message.event) {
        case WebsocketEvent.QUANTITY_RESTRICTION_CHANGED:
          this.store.dispatch(new UpdateQuantityRestriction(message));
          break;
        case WebsocketEvent.QUANTITY_RESTRICTION_DELETED:
          this.store.dispatch(new DeleteQuantityRestriction(message));
          break;
      }
    }
  }
  private handleTakeOutOrderStatusChangeEvent(message: WSMessage) {
    if (message.event !== WebsocketEvent.TAKEOUT_ORDER_STATUS_CHANGE) {
      return;
    }

    this.lgProvider.log('WS Takeout Delivery Event', message);
    const wsEventData = message.data,
      isOrderEntry =
        wsEventData.takeout_order_status === TakeoutOrderStatus.ORDER_ENTRY;

    // if the event is order entry, we don't need to update the status
    if (isOrderEntry) {
      return;
    }

    this.store.dispatch(new UpdateTakeoutDeliveryStatus(wsEventData));
  }

  private handleTicketLockChangeEvent(
    message: WSMessage,
    deviceId: number,
    selectedTableId: number,
    isAtTakeoutDeliveryListingPage: boolean
  ) {
    if (message.event !== WebsocketEvent.TICKET_LOCK_CHANGED) {
      return;
    }

    this.lgProvider.log('WS Ticket Lock Change Event', message);

    const ticketLockedData = message.data;
    const { table_id, device_ticket_uuid } = ticketLockedData;
    const isIncludeTicket = this.store
      .selectSnapshot(TICKETS_STATE_TOKEN)
      .tickets.allIds.includes(device_ticket_uuid);
    const isTriggerByThisDevice =
      deviceId === ticketLockedData.triggered_by_device_id;

    if (isTriggerByThisDevice || !isIncludeTicket) {
      return;
    }

    if (!!table_id && table_id === selectedTableId) {
      this.store.dispatch(new TicketLockedChangedFromWS(ticketLockedData));
    } else if (isAtTakeoutDeliveryListingPage) {
      this.store.dispatch(new UpdateTakeoutDeliveryLocked(message.data));
    }
  }

  private handleDiningRoomEvents(
    message: WSMessage,
    deviceId: number,
    selectedTableId: number
  ) {
    // ! IMPORTANT: triggered_by_device_id is not trust because it's not always the device id (raised to BE but not fixed yet)
    switch (message.event) {
      case WebsocketEvent.TICKET_CREATED:
        this.store.dispatch(new TicketCreatedWsEvent(message.data));

        if (message.data.triggered_by_device_id === deviceId) {
          return;
        }

        if (selectedTableId === +message.data.table_id) {
          this.store.dispatch(new CreateTicketWsEvent(message.data));
        } else {
          const {
            seat_id: id,
            device_ticket_uuid,
            table_id,
            ticket_created_at
          } = message.data;
          this.store.dispatch(
            new UpdateLocalSeat({
              id,
              device_ticket_uuid,
              tableId: table_id,
              ticket_created_at
            })
          );
        }
        break;
      case WebsocketEvent.TICKET_CLOSED:
        this.store.dispatch(new TicketClosedWsEvent(message.data));
        if (message.data.triggered_by_device_id !== deviceId) {
          this.store.dispatch([new CloseTicketWsEvent(message.data)]);
        }

        break;
      case WebsocketEvent.FLOOR_PLAN_UPDATED:
        const tableId: number = message.data.table_id,
          isTableInvalid = !tableId,
          isRefundTicket: boolean = [
            TicketStatus.FULL_REFUND,
            TicketStatus.PARTIAL_REFUND
          ].includes(message.data.new_status),
          noNeedHandle: boolean = isTableInvalid || isRefundTicket;

        if (noNeedHandle) {
          return;
        }

        this.store.dispatch(new FloorplanOrderUpdatedWS(message.data));
        break;
      case WebsocketEvent.TABLE_ASSIGNMENT_CHANGE:
        if (message.data.triggered_by_device_id !== deviceId) {
          return;
        }
        const tableIds =
          message.data.table_ids?.split(',').map((x) => +x) || [];
        const staffId = parseFloat(message.data.operator_id) || null;
        if (tableIds.length) {
          this.store.dispatch(new UpdateDataAfterAssignment(tableIds, staffId));
        }
        break;
      case WebsocketEvent.PENDING_SEAT_ASSIGNMENT_CREATED:
        return this.store.dispatch(
          new PendingSeatCreatedWsEvent(message.table_id)
        );
      case WebsocketEvent.PENDING_SEAT_ASSIGNMENT_SEATED:
        return this.store.dispatch(
          new PendingSeatAssignmentSeatedWsEvent(
            +message.pending_seat_assignment_id,
            message.table_id
          )
        );
      case WebsocketEvent.PENDING_SEAT_ASSIGNMENT_DELETED:
        return this.store.dispatch(
          new PendingAssignmentDeletedWsEvent(
            +message.pending_seat_assignment_id,
            message.table_id
          )
        );

      case WebsocketEvent.RESERVATION_CREATED:
        this.store.dispatch(new ReservationCreatedWsEvent(message.data));
        if (message.data.triggered_by_device_id === deviceId) {
          return;
        }
        return this.store.dispatch(
          new CreateTableReservationSuccess(message.data)
        );
      case WebsocketEvent.RESERVATION_UPDATED:
        if (message.data.triggered_by_device_id === deviceId) {
          return;
        }
        return this.store.dispatch(
          new EditTableReservationSuccess(message.data)
        );
      case WebsocketEvent.RESERVATION_DELETED:
        if (message.data.triggered_by_device_id === deviceId) {
          return;
        }
        this.store.dispatch(new DeleteTableReservationSuccess(message.data.id));
        break;
      case WebsocketEvent.FIRE_ORDER:
        if (
          message.data.triggered_by_device_id === deviceId ||
          selectedTableId !== message.data.table_id
        ) {
          return;
        }

        if (!!message.data.device_ticket_item_uuid) {
          this.store.dispatch(
            new FireTicketItemNotificationEvent(message.data)
          );
          return;
        }

        if (_.isNumber(message.data.course)) {
          if (message.data.seat_id) {
            this.store.dispatch(
              new FireCourseSeatNotificationEvent(message.data)
            );
            return;
          }

          this.store.dispatch(new FireCourseNotificationEvent(message.data));
          return;
        }

        if (!!message.data.device_ticket_uuid) {
          this.store.dispatch(new FireTicketNotificationEvent(message.data));
          return;
        }

        this.store.dispatch(new FireTableNotificationEvent(message.data));
        break;
      case WebsocketEvent.TABLE_STATUS_CHANGED:
        this.store.dispatch(
          new TableStatusChangedWs(message.table_id, message.status)
        );
        break;
      case WebsocketEvent.FLOOR_PLAN_LAYOUT_CHANGED:
        this.store.dispatch(new GetTables());
        break;
    }
  }
}
