import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  TABLE_RESERVATIONS_STATE_TOKEN,
  TableReservationsStateModel
} from './table-reservation.model.state';
import {
  CreateTableReservation,
  CreateTableReservationError,
  CreateTableReservationSuccess,
  DeleteTableReservation,
  DeleteTableReservationError,
  DeleteTableReservationSuccess,
  EditTableReservation,
  EditTableReservationError,
  EditTableReservationSuccess,
  GetTableReservations,
  GetTableReservationsError,
  GetTableReservationsSuccess,
  SetDinerSearchTableReservations,
  SetReservationViewFocus
} from './table-reservation.action';
import { DiningService } from 'src/app/services';
import { catchError, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { ReservationViewFocus } from 'src/app/models';
import { DateHelper } from 'src/app/helpers';

@State({
  name: TABLE_RESERVATIONS_STATE_TOKEN,
  defaults: {
    date: DateHelper.format(new Date()),
    diner_name: '',
    reservations: [],
    dinerSearchReservations: [],
    reservationViewFocus: ReservationViewFocus.LIST
  }
})
@Injectable()
export class TableReservationsState {
  constructor(private readonly diningService: DiningService) {}

  @Action(GetTableReservations, { cancelUncompleted: true })
  getTableReservations(
    ctx: StateContext<TableReservationsStateModel>,
    { date, diner_name }: GetTableReservations
  ) {
    ctx.patchState({ date, diner_name });

    return this.diningService.getTableReservations(date, diner_name).pipe(
      tap((res) =>
        ctx.dispatch(
          new GetTableReservationsSuccess(res.reservations, !!diner_name)
        )
      ),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(new GetTableReservationsError(error))
      )
    );
  }

  @Action(GetTableReservationsSuccess)
  getTableReservationsSuccess(
    ctx: StateContext<TableReservationsStateModel>,
    { reservations, isDinerSearching }: GetTableReservationsSuccess
  ) {
    if (isDinerSearching) {
      return ctx.patchState({ dinerSearchReservations: reservations });
    }
    ctx.patchState({ reservations });
  }

  @Action(SetDinerSearchTableReservations)
  setDinerSearchTableReservations(
    ctx: StateContext<TableReservationsStateModel>,
    { reservations }: SetDinerSearchTableReservations
  ) {
    ctx.patchState({ dinerSearchReservations: reservations });
  }

  @Action(CreateTableReservation)
  createTableReservations(
    ctx: StateContext<TableReservationsStateModel>,
    { dto }: CreateTableReservation
  ) {
    return this.diningService.createTableReservation(dto).pipe(
      tap((res) => ctx.dispatch(new CreateTableReservationSuccess(res))),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(new CreateTableReservationError(error))
      )
    );
  }

  @Action(CreateTableReservationSuccess)
  createTableReservationSuccess(
    ctx: StateContext<TableReservationsStateModel>,
    { reservation }: CreateTableReservationSuccess
  ) {
    const { date, diner_name } = ctx.getState();

    if (
      !!diner_name &&
      reservation.reservation_diners.some((diner) =>
        diner.diner_name
          .toLocaleLowerCase()
          .includes(diner_name.toLocaleLowerCase())
      )
    ) {
      return ctx.setState(
        patch({
          dinerSearchReservations: insertItem(reservation)
        })
      );
    }

    if (date === reservation.date) {
      return ctx.setState(
        patch({
          reservations: insertItem(reservation)
        })
      );
    }
  }

  @Action(EditTableReservation)
  editTableReservation(
    ctx: StateContext<TableReservationsStateModel>,
    { id, dto }: EditTableReservation
  ) {
    return this.diningService.editTableReservation(id, dto).pipe(
      tap((res) => ctx.dispatch(new EditTableReservationSuccess(res))),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(new EditTableReservationError(error))
      )
    );
  }

  @Action(EditTableReservationSuccess)
  editTableReservationSuccess(
    ctx: StateContext<TableReservationsStateModel>,
    { reservation }: EditTableReservationSuccess
  ) {
    ctx.setState((state) => {
      if (state.diner_name) {
        const dinerSearchIndex = state.dinerSearchReservations.findIndex(
          (item) => item.id === reservation.id
        );

        if (dinerSearchIndex > -1) {
          return {
            ...state,
            dinerSearchReservations: [
              ...state.dinerSearchReservations.slice(0, dinerSearchIndex),
              reservation,
              ...state.dinerSearchReservations.slice(dinerSearchIndex + 1)
            ]
          };
        }
      } else {
        const index = state.reservations.findIndex(
          (item) => item.id === reservation.id
        );

        if (reservation.date !== state.date) {
          return {
            ...state,
            reservations: [
              ...state.reservations.slice(0, index),
              ...state.reservations.slice(index + 1)
            ]
          };
        }

        if (index > -1) {
          return {
            ...state,
            reservations: [
              ...state.reservations.slice(0, index),
              reservation,
              ...state.reservations.slice(index + 1)
            ]
          };
        }

        if (state.date === reservation.date) {
          return {
            ...state,
            reservations: [...state.reservations, reservation].sort((a, b) => {
              if (a.meal_id === b.meal_id) {
                return (
                  DateHelper.parse(a.start_time).getTime() -
                  DateHelper.parse(b.start_time).getTime()
                );
              }

              return a.meal_id - b.meal_id;
            })
          };
        }
      }

      return state;
    });
  }

  @Action(DeleteTableReservation)
  deleteTableReservation(
    ctx: StateContext<TableReservationsStateModel>,
    { reservation }: DeleteTableReservation
  ) {
    return this.diningService.deleteTableReservation(reservation.id).pipe(
      tap(() =>
        ctx.dispatch(new DeleteTableReservationSuccess(reservation.id))
      ),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(new DeleteTableReservationError(error))
      )
    );
  }

  @Action(DeleteTableReservationSuccess)
  deleteTableReservationSuccess(
    ctx: StateContext<TableReservationsStateModel>,
    { id }: DeleteTableReservationSuccess
  ) {
    ctx.setState(
      patch({
        reservations: removeItem((item) => item.id === id)
      })
    );
  }

  @Action(SetReservationViewFocus)
  setReservationViewFocus(
    { patchState }: StateContext<TableReservationsStateModel>,
    { view }: SetReservationViewFocus
  ) {
    patchState({ reservationViewFocus: view });
  }
}
